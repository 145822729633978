import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Table from "../../components/Datatable/SearchFilterTable";
import Dropdown from "../../components/DynamicComponents/DynamicDropdown";
import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import { BASE_URI } from "../../utils/BaseURL";
import Loader from "../../utils/Loader";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SaleReportMonthly = () => {
  const [filter, setFilter] = useState("All");
  const [agentOptions, setAgentOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const navigate = useNavigate();
  const BASE_URL = BASE_URI;
  const token = Cookies.get("token");
  let LogId, DPCode;

  const Depot = JSON.parse(localStorage.getItem("Depo"));
  const selectedLocation = localStorage.getItem("selectedLocation");

  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });

  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  LogId = itemFromLocalStorage[0].LogID;

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  // Fetch agents data
  useEffect(() => {
    const fetchAgentsData = async () => {
      try {
        const requestBody = {
          LogID: LogId,
          FormKey: 433, // For fetching all agents
          Depo: DPCode,
        };
        const response = await axios.post(
          `${BASE_URL}/agents`,
          requestBody,
          config
        );
        const agentsData = response.data.resultWebAgentList.map((agent) => ({
          value: agent.Aname,
          label: agent.Aname,
        }));
        setAgentOptions([{ value: "All", label: "All" }, ...agentsData]);
      } catch (error) {
        console.error("Error fetching agents data:", error);
        if (error.response?.status === 401) {
          alert("Your Token is expired Please Login Again!!!");
          navigate("/login");
        } else {
          alert("Something went wrong!!!");
        }
      }
    };

    fetchAgentsData();
  }, [DPCode, LogId, BASE_URL, token, navigate]);

  const columns = useMemo(
    () => [
      {
        Header: "S No",
        Cell: ({ row }) => (
          <div style={{ textAlign: "center", width: "50px" }}>
            {row.original.isTotalRow ? "" : row.index + 1}
          </div>
        ),
        isInitiallyVisible: true,
      },
      {
        accessor: "PartyName",
        Header: "Party Name",
        Cell: ({ value }) => (
          <div style={{ width: "150px", textAlign: "left" }}>{value}</div>
        ),
        isInitiallyVisible: true,
      },
      ...[...monthNames.slice(3), ...monthNames.slice(0, 3)].map((month) => ({
        accessor: month,
        Header: month,
        Cell: ({ value }) => (
          <div style={{ width: "80px", textAlign: "right" }}>
            {value ? parseFloat(value).toFixed(2) : " - "}
          </div>
        ),
        isInitiallyVisible: true,
      })),
      {
        accessor: "TotalValue",
        Header: "Total Value",
        Cell: ({ value }) => (
          <div style={{ width: "100px", textAlign: "right" }}>
            {value ? parseFloat(value).toFixed(2) : ""}
          </div>
        ),
        isInitiallyVisible: true,
      },
    ],
    []
  );

  const fetchReportData = async () => {
    setShowLoader(true);
    try {
      const requestBody = {
        LogID: LogId,
        FormKey: 433,
        Depo: DPCode,
        PartyName: "", // Assuming party name is not needed as we are filtering by agent
        AgentName: filter === "All" ? "" : filter,
        ItemGroup: "",
        ItemName: "",
        Date1: "",
        Date2: "",
        RptCode: 0,
      };

      const response = await axios.post(
        `${BASE_URL}/web-reports`,
        requestBody,
        config
      );
      const data = response.data.results;
      const transformedData = transformData(data);
      setTableData(transformedData);
    } catch (error) {
      console.error("Error while fetching the Data", error);
      if (error.response?.status === 401) {
        alert("Your Token is expired Please Login Again!!!");
        navigate("/login");
      } else {
        alert("Something went wrong!!!");
      }
    } finally {
      setShowLoader(false);
    }
  };

  const transformData = (data) => {
    const partyDataMap = {};

    data.forEach((item) => {
      const partyName = item.PartyName;
      let month = monthNames[item.Months - 1];

      const taxableValue = item.TaxableValue || 0;
      if (!partyDataMap[partyName]) {
        partyDataMap[partyName] = {
          PartyName: partyName,
          [month]: taxableValue.toFixed(2),
          TotalValue: taxableValue,
        };
      } else {
        partyDataMap[partyName][month] = taxableValue.toFixed(2);
        partyDataMap[partyName].TotalValue += taxableValue;
      }
    });

    return Object.values(partyDataMap);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchReportData();
  };

  const calculateTotals = () => {
    const totals = {
      PartyName: "Total",
      ...monthNames.reduce((acc, month) => ({ ...acc, [month]: 0 }), {}),
      TotalValue: 0,
      isTotalRow: true,
    };

    tableData.forEach((row) => {
      monthNames.forEach((month) => {
        totals[month] += parseFloat(row[month] || 0);
      });
      totals.TotalValue += parseFloat(row.TotalValue || 0);
    });

    return totals;
  };

  const totalsRow = calculateTotals();

  useEffect(() => {
    document.title = "PTAC ERP || Sale Report";

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500); // Set loader to false after 1500 milliseconds

    return () => clearTimeout(timer);
  }, []);

  const handleFilterChange = (e) => {
    const newValue = e.target.value; // Get the new value from the dropdown
    // Reset both dropdowns to their default values when the first dropdown changes
    setFilter(newValue); // Update first dropdown
    setTableData([]);
    // setDropdown2(""); // Reset second dropdown to "All" (default value)
  };

  return (
    <div className='p-1 mx-auto bg-gray-50 rounded-lg shadow-md'>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-1 mb-1'>
        <h1 className='text-sm uppercase text-center font-bold'>
          Sale Report Monthly
        </h1>
        <form onSubmit={handleSubmit} className=''>
          <div className='grid grid-cols-2 lg:grid-cols-2 gap-2'>
            <Dropdown
              options={agentOptions}
              value={filter}
              onChange={handleFilterChange}
              label='Agent Name'
              isDisabled={false}
            />
            <div className='grid justify-end mt-5'>
              <DynamicButton variant='primary' label='Show' />
            </div>
          </div>
        </form>
      </div>
      <Table columns={columns} data={[...tableData, totalsRow]} />
    </div>
  );
};

export default SaleReportMonthly;
