import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaHome,
  FaFileAlt,
  FaInfoCircle,
  FaChartBar,
  FaExchangeAlt,
  FaDatabase,
  FaSearch,
  FaChartLine,
  FaMoneyBillWave,
  FaWarehouse,
} from "react-icons/fa";
import { MdAccountBalanceWallet } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import {
  FaBalanceScale,
  FaUserCheck,
  FaClipboardList,
  FaRegFileAlt,
  FaRegListAlt,
  FaRegMoneyBillAlt,
} from "react-icons/fa";
import {
  RiFileList2Line,
  RiShoppingCartLine,
  RiFileList3Line,
  RiFileListLine,
  RiFileList2Fill,
  RiFileListFill,
} from "react-icons/ri";

import { FiUserCheck, FiUsers } from "react-icons/fi";
import {
  FaShoppingCart,
  FaRegChartBar,
  FaUserTie,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { BsCardChecklist, BsBook } from "react-icons/bs";
import { RiFileTextLine } from "react-icons/ri";
import {
  AiOutlineCar,
  AiOutlineFileExclamation,
  AiOutlineFileDone,
} from "react-icons/ai";
import { MdInventory2 } from "react-icons/md";

// Define your icon mapping
const iconMapping = {
  147: <FaChartLine style={{ color: "#4CAF50" }} size={30} />, // Green for verification
  197: <FiUserCheck style={{ color: "#4CAF50" }} size={30} />, // Green for verification

  204: <FaShoppingCart style={{ color: "#FF9800" }} size={30} />, // Orange for sale/order

  303: <FaClipboardList style={{ color: "#2196F3" }} size={30} />, // Blue for records/registers
  327: <BsCardChecklist style={{ color: "#3F51B5" }} size={30} />, // Indigo for item register

  423: <FaWarehouse style={{ color: "#795548" }} size={30} />, // Brown for stock/inventory
  433: <FaRegChartBar style={{ color: "#673AB7" }} size={30} />, // Deep purple for reports/charts
  444: <RiFileTextLine style={{ color: "#607D8B" }} size={30} />, // Teal-grey for reports/data
  451: <AiOutlineFileExclamation style={{ color: "#F44336" }} size={30} />, // Red for outstanding (payable)
  452: <AiOutlineFileDone style={{ color: "#8BC34A" }} size={30} />, // Light green for outstanding (receivable)
  453: <AiOutlineCar style={{ color: "#00BCD4" }} size={30} />, // Cyan for dispatch
  467: <RiFileList3Line style={{ color: "#9C27B0" }} size={30} />, // Purple for consolidated list

  1206: <BsBook style={{ color: "#FF5722" }} size={30} />, // Deep orange for ledger
  1207: <FaRegCalendarAlt style={{ color: "#FFC107" }} size={30} />, // Yellow for calendar/daybook
  1208: <FaMoneyBillWave style={{ color: "#9E9E9E" }} size={30} />, // Grey for debtors (business-related)

  1408: <FiUsers style={{ color: "#03A9F4" }} size={30} />, // Light blue for master data
};

const QuickLinks = () => {
  const [formDetails, setFormDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLinks, setFilteredLinks] = useState([]);
  const navigate = useNavigate();
  const storedFormDetails = localStorage.getItem("Menu");

  useEffect(() => {
    // Fetch and parse formDetails from localStorage
    if (storedFormDetails) {
      const details = JSON.parse(storedFormDetails);
      setFormDetails(details);
      setFilteredLinks(details.filter((item) => item.SubCategory === 0));
    }
  }, []);

  useEffect(() => {
    // Filter the formDetails based on search query
    const searchResults = formDetails.filter(
      (item) =>
        item.FormName.toLowerCase().includes(searchQuery.toLowerCase()) &&
        item.SubCategory === 0
    );
    setFilteredLinks(searchResults);
  }, [searchQuery, formDetails]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Navigate to the first result if any
      if (filteredLinks.length > 0) {
        navigate(
          `/dashboard/${filteredLinks[0].FormName.replace(
            /\s+/g,
            "-"
          ).toLowerCase()}`
        );
      }
    }
  };
  return (
    <div className='p-1 rounded-2xl '>
      <div className='flex justify-between items-center mb-4 '>
        <h2 className='text-sm font-semibold underline'>Quick Links</h2>
        <div className='relative w-1/2 max-w-xs '>
          <FaSearch className='absolute top-1/2 transform -translate-y-1/2 left-3 text-white ' />
          <input
            type='text'
            placeholder='Search'
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            className='pl-10  py-1 border rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 dark:bg-gray-200 dark:text-white w-full '
          />
        </div>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-3'>
        {filteredLinks.map((item) => (
          <Link
            key={item.FormKey}
            to={`/dashboard/${item.FormName.replace(
              /\s+/g,
              "-"
            ).toLowerCase()}`}
            className={`flex items-center p-1 border border-gray-200 rounded-lg shadow-md hover:scale-95 duration-700  `}
          >
            {iconMapping[item.FormKey] || <FaHome size={25} />}
            <span className='text-xs ml-2'>{item.FormName}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default QuickLinks;
