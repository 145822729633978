import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loader";
import axios from "axios";
import { BASE_URI } from "../../utils/BaseURL";

const VisitorsEntry = ({ tokens }) => {
  const [token, setToken] = useState("");
  const [logo, setLogo] = useState("");
  const [states, setStates] = useState([]);
  const [whatsappData, setWhatsappData] = useState([]);

  const staticToken =
    "AxktlLqcQUdGGA4sKIsxaStKELfUXtjpbw4ithEzew0y4YYtKGyQ3XRqeKYLGGoyLXFkFV9Dd3sN14POWVks4DwoOOdSs32JhCWguOXXG4M9Fdyk4jXTv6DiBqF0Sqkj";

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  const BASE_URL = BASE_URI;

  const [showLoader, setShowLoader] = useState(true);
  const [formData, setFormData] = useState({
    retailerName: "",
    contactPerson: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    district: "",
    state: "",
    marriageDate: "",
    birthDate: "",
    aadharNumber: "",
    mobileNumber: "",
    email: "",
    pan: "",
    whatsappNumber: "",
  });

  const mandatoryFields = [
    "mobileNumber",
    "contactPerson",
    "retailerName",
    "city",
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let inputValue = value;

    if (name === "gstin" || name === "pan") {
      inputValue = value.toUpperCase();
    }

    // Ensure only numbers are allowed for aadhar, mobile, and whatsapp
    if (
      name === "aadharNumber" ||
      name === "mobileNumber" ||
      name === "whatsappNumber"
    ) {
      inputValue = inputValue.replace(/\D/g, "");
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : inputValue,
    }));
  };

  const validateForm = () => {
    for (const field of mandatoryFields) {
      if (!formData[field]) {
        console.log(field);
        if (field === "retailerName") {
          alert("Company Name is required");
          return false;
        }
        alert(`${field.toLocaleUpperCase()} is required`);
        return false;
      }
    }

    if (formData.isMarried && !formData.marriageDate) {
      alert("Marriage Date is required");
      return false;
    }

    if (formData.isDOB && !formData.birthDate) {
      alert("Birth Date is required");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(token);
    if (!validateForm()) return;

    const payload = {
      DistName: "",
      RegType: "",
      GSTIN: formData.gstin,
      RetailerName: formData.retailerName,
      CPName: formData.contactPerson,
      Add1: formData.address1,
      Add2: formData.address2,
      City: formData.city,
      Pincode: formData.pincode,
      District: formData.district,
      State: formData.state,
      AadhaarNo: "",
      MobileNo: formData.mobileNumber,
      Email: formData.email,
      PAN: "",
      WhatsappNo: "",
      DOM: "",
      MarriageDate: "",
      DOB: "",
      BirthDate: "",
      Depo: "",
      LogID: "",
    };

    try {
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}/retail-data`,
        payload,
        config
      );
      alert("Visitors Entry successfully created!!!");
      await sendWhatsAppMessage(formData.mobileNumber);
      setFormData({
        distributor: "",
        regType: "",
        gstin: "",
        retailerName: "",
        contactPerson: "",
        address1: "",
        address2: "",
        city: "",
        pincode: "",
        district: "",
        state: "",
        marriageDate: "",
        birthDate: "",
        aadharNumber: "",
        mobileNumber: "",
        email: "",
        pan: "",
        whatsappNumber: "",
        isMarried: false,
        isDOB: false,
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setShowLoader(false);
    }
  };

  // Fetch data from API
  const handleLoginClick = async () => {
    if (true) {
      const newErrors = {};
      if (Object.keys(newErrors).length > 0) {
      } else {
        setShowLoader(true);
        const credentials = {
          LogID: staticToken,
          LogPwd: "111",
        };
        try {
          const response = await axios.post(
            `${BASE_URL}/auth/login`,
            credentials
          );
          setLogo(response.data?.responseData?.UserID[0]?.Logo);
          setToken(response.data.token);
        } catch (error) {
          console.error("Error logging in:", error);
        } finally {
          setShowLoader(false);
        }
      }
    }
  };
  const fetchData = async () => {
    try {
      const requestBody = {
        LogID: "",
        FormKey: "",
        Depo: "",
        PartyName: "",
        AgentName: "",
        ItemGroup: "",
        ItemName: "",
        Date1: "",
        Date2: "",
        RptCode: 1102,
      };
      const response = await axios.post(
        `${BASE_URL}/web-app-report`,
        requestBody,
        config
      );
      const data = response.data.results;
      // If `data` is an array with nested arrays, extract the first array
      if (Array.isArray(data) && Array.isArray(data[0])) {
        setStates(data[0]); // Extract inner array
      } else {
        setStates(data); // Set data directly if no extra nesting
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response.status === 401) {
      } else {
        alert("Something went wrong!!!");
      }
    }
  };
  const fetchWhatsappData = async () => {
    try {
      const requestBody = {
        LogID: "",
        FormKey: "",
        Depo: "",
        PartyName: "",
        AgentName: "",
        ItemGroup: "",
        ItemName: "",
        Date1: "",
        Date2: "",
        RptCode: 1103,
      };
      const response = await axios.post(
        `${BASE_URL}/web-app-report`,
        requestBody,
        config
      );
      const data = response.data.results;
      if (Array.isArray(data) && Array.isArray(data[0])) {
        setWhatsappData(data[0]);
      } else {
        setWhatsappData(data);
      }
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response.status === 401) {
      } else {
        alert("Something went wrong!!!");
      }
    }
  };

  useEffect(() => {
    {
      token && fetchData();
      token && fetchWhatsappData();
    }
  }, [token]);

  // Effect to handle login and fetchData
  useEffect(() => {
    const performLoginAndFetch = async () => {
      await handleLoginClick(); // Perform login
    };

    performLoginAndFetch();
  }, []);

  useEffect(() => {
    document.title = "PTAC ERP || Visitor Entry";
    let timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const sendWhatsAppMessage = async (mobileNumber) => {
    const userName = whatsappData[0]?.UserID; // AutoSender username
    const password = whatsappData[0]?.UserPwd; // AutoSender password
    const receiverPhoneNumber = mobileNumber; // Constant Number all msg on this number

    // Structure the message with form data (no icons since WhatsApp only supports text)
    const message = whatsappData[0]?.WAMessage;

    // Encode the message to ensure proper transmission
    const encodedMessage = encodeURIComponent(message);

    // API URL for AutoSender service
    const apiUrl = `https://app.messageautosender.com/api/v1/message/create?username=${userName}&password=${password}&receiverMobileNo=${receiverPhoneNumber}&message=${encodedMessage}`;
    const response = await axios.post(apiUrl);
    alert("Message sent successfully to the receiver.");
  };

  return (
    <div className=' mx-auto bg-gray-50 rounded-lg shadow-md p-5 min-h-screen'>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-4 mb-1'>
        <div className='flex space-between items-center'>
          <img src={logo} className='w-20' />
          <h1 className='text-sm text-center font-bold uppercase mx-auto'>
            VISITORS ENTRY FORM
          </h1>
        </div>

        <form onSubmit={handleSubmit} className='space-y-5'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1 '>
            <div>
              <label className='block font-semibold text-sm'>
                Mobile Number <span className='text-red-600'>*</span>
              </label>
              <input
                type='tel'
                name='mobileNumber'
                value={formData.mobileNumber}
                onChange={handleChange}
                className='w-full p-1 border rounded'
                maxLength={12} // Limit length to 10 digits
              />
            </div>
            <div>
              <label className='block font-semibold text-sm'>
                Contact Person <span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                name='contactPerson'
                value={formData.contactPerson}
                onChange={handleChange}
                className='w-full p-1 border rounded'
              />
            </div>
            <div>
              <label className='block font-semibold text-sm'>
                Company Name <span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                name='retailerName'
                value={formData.retailerName}
                onChange={handleChange}
                className='w-full p-1 border rounded'
              />
            </div>
            <div>
              <label className='block font-semibold text-sm'>
                City <span className='text-red-600'>*</span>
              </label>
              <input
                type='text'
                name='city'
                value={formData.city}
                onChange={handleChange}
                className='w-full p-1 border rounded'
              />
            </div>
            <div>
              <label className='block font-semibold text-sm'>Address</label>
              <input
                type='text'
                name='address1'
                value={formData.address1}
                onChange={handleChange}
                className='w-full p-1 border rounded'
              />
            </div>
            <div>
              <label className='block font-semibold text-sm'>State</label>
              <select
                name='state'
                value={formData.state}
                onChange={handleChange}
                className='w-full p-1 border rounded'
              >
                <option value=''>Select a state</option>
                {states.map((state, index) => (
                  <option key={index} value={state.StName}>
                    {state.StName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className='block font-semibold text-sm'>Email</label>
              <input
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                className='w-full p-1 border rounded'
              />
            </div>
            <div>
              <label className='block font-semibold text-sm'>GSTIN</label>
              <input
                type='text'
                name='gstin'
                value={formData.gstin}
                onChange={handleChange}
                className='w-full p-1 border rounded'
              />
            </div>
          </div>

          <div className='text-right mt-2'>
            <button
              type='submit'
              className='px-4 py-2 bg-gray-500 text-white rounded w-full text-sm'
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VisitorsEntry;
