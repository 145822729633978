import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Header from "./Header";
import { RiMenu2Fill, RiMenu3Fill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sideBarData, setSideBarData] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("home");
  const [userInfo, setUserInfo] = useState(false);

  const location = useLocation();
  const userData = location.state;
  const sideBarItems = location.state?.menu;

  useEffect(() => {
    setUserInfo(userData);
    setSideBarData(sideBarItems);
  }, [userData, sideBarItems]);

  const handleSidebarToggle = () => {
    setSidebarOpen((prev) => !prev); // Toggle sidebar state
  };

  const handleIconClick = (iconId) => {
    if (selectedIcon !== iconId) {
      setSidebarOpen(true); // Open sidebar for new icon
    }
    setSelectedIcon(iconId);
  };

  const handleFormNameClick = () => {
    setSidebarOpen(false);
  };

  const user = JSON.parse(localStorage.getItem("userInfo")) || [];
  let typeOfUser = user[0]?.UserType;

  return (
    <div
      className={`flex flex-col lg:flex-row ${
        darkMode ? "bg-gray-800" : "bg-gray-100"
      } h-screen`}
    >
      {/* Conditionally Render Navbar and Sidebar */}
      {typeOfUser !== 3 && (
        <>
          {/* Navbar */}
          <div
            className={`hidden lg:flex flex-col w-[5%] transition-width duration-700 ${
              darkMode ? "bg-gray-700" : "bg-white shadow-2xl"
            }`}
          >
            <Navbar
              darkMode={darkMode}
              onIconClick={handleIconClick}
              data={sideBarData}
            />
          </div>

          {/* Collapsible Sidebar */}
          <div
            className={`bg-gray-400 ${
              sidebarOpen ? "lg:w-[15%] border border-l-gray-800" : "lg:w-[4%]"
            } transition-width duration-300 hidden lg:flex flex-col ${
              darkMode ? "bg-gray-700" : "bg-white border border-l-gray-300"
            }`}
          >
            <button
              onClick={handleSidebarToggle}
              className='mb-10 shadow-2xl m-1 text-3xl text-gray-600 font-bold'
            >
              {sidebarOpen ? (
                <RiMenu3Fill size={35} className='font-bold' />
              ) : (
                <RiMenu2Fill size={35} />
              )}
            </button>
            <Sidebar
              selectedIcon={selectedIcon}
              sidebarOpen={sidebarOpen}
              data={sideBarData}
              onFormNameClick={handleFormNameClick}
            />
          </div>
        </>
      )}

      {/* Main Content */}
      <div
        className={`flex-grow flex p-1 flex-col ${
          darkMode ? "bg-gray-400" : "bg-white"
        } ${typeOfUser === 3 ? "w-full" : "w-full lg:w-[70%]"}`}
      >
        <Header
          onToggleSidebar={handleSidebarToggle}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          userInfo={userInfo}
          data={sideBarData}
          onIconClick={handleIconClick}
        />
        <div className='flex-grow overflow-y-auto overflow-x-hidden'>
          <Content darkMode={darkMode} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
