import React, { useState, useEffect } from "react";
import Table from "../../components/Datatable/SearchFilterTable";
import Dropdown from "../../components/DynamicComponents/DynamicDropdown";
import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import Loader from "../../utils/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { BASE_URI } from "../../utils/BaseURL";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { MdGetApp } from "react-icons/md";

const columns = [
  {
    Header: "S No",
    accessor: "sNo",
    align: "center",
    headerAlign: "center",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {row.original.isTotalRow ? "" : row.index + 1}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    accessor: "BillRemark",
    Header: "Doc No",
    align: "left",
    headerAlign: "center",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    accessor: "TrDate",
    Header: "Doc Date",
    align: "center",
    headerAlign: "center",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    accessor: "AName",
    Header: "Agent Name",
    align: "left",
    headerAlign: "center",
    Cell: ({ value }) => <div style={{}}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    accessor: "LName",
    Header: "Party Name",
    align: "left",
    headerAlign: "center",
    Cell: ({ value }) => <div style={{}}>{value}</div>,
    isInitiallyVisible: true,
  },
  {
    accessor: "DrAmt",
    Header: "Debit",
    align: "right",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
    ),
    isInitiallyVisible: true,
  },
  {
    accessor: "CrAmt",
    Header: "Credit",
    align: "right",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
    ),
    isInitiallyVisible: true,
  },
  {
    accessor: "Balance",
    Header: "Balance",
    align: "right",
    headerAlign: "center",
    Cell: ({ row }) => {
      // Get debit and credit values from the row
      const debit = parseFloat(row.original.DrAmt) || 0;
      const credit = parseFloat(row.original.CrAmt) || 0;

      // Calculate balance
      const balance = debit - credit;

      // Format the balance value
      const displayBalance = balance.toFixed(2);

      return <div style={{ textAlign: "right" }}>{displayBalance}</div>;
    },
    isInitiallyVisible: true,
  },
  {
    accessor: "PDays",
    Header: "Days",
    align: "center",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: "center",
          backgroundColor:
            parseFloat(value) > 60 ? "bg-gray-200" : "transparent",
          color: parseFloat(value) > 60 ? "text-white" : "inherit",
        }}
      >
        {value}
      </div>
    ),
    isInitiallyVisible: true,
  },
];

const ReceivableBills = () => {
  const [filter, setFilter] = useState("Agentwise"); // Default filter to 'Agentwise'
  const [dropdown2, setDropdown2] = useState("All"); // Default dropdown value
  const [selectedDate, setSelectedDate] = useState(null);
  const [agentNames, setAgentNames] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [dropdown2Options, setDropdown2Options] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);

  // Basic Information For API's
  let targetRoute = "Bills Outstanding [Receivable]";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;
  const token = Cookies.get("token");
  let LogId, DPCode, Type;

  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });

  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  LogId = itemFromLocalStorage[0].LogID;
  Type = itemFromLocalStorage[0].UserType;
  const bank = JSON.parse(localStorage.getItem("bankData"));

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  const BASE_URL = BASE_URI;

  useEffect(() => {
    fetchAgentNames();
    fetchPartyNames();
  }, []);

  useEffect(() => {
    if (filter === "Agentwise") {
      const formattedAgentNames = agentNames.map((agent) => ({
        value: agent.Aname,
        label: agent.Aname,
      }));
      setDropdown2Options([
        { value: "All", label: "All" },
        ...formattedAgentNames,
      ]);
    } else if (filter === "Partywise") {
      const formattedPartyNames = partyNames.map((party) => ({
        value: party.LName,
        label: party.LName,
      }));
      setDropdown2Options([
        { value: "All", label: "All" },
        ...formattedPartyNames,
      ]);
    }
  }, [filter, agentNames, partyNames]);

  useEffect(() => {
    // Clear rows when filter or dropdown2 value changes
    setRows([]);
  }, [filter, dropdown2]);

  const fetchAgentNames = async () => {
    setShowLoader(true);
    try {
      const requestBody = { LogID: LogId, FormKey: FormKey, Depo: DPCode };
      const response = await axios.post(
        `${BASE_URL}/agents`,
        requestBody,
        config
      );
      const Agents = response.data.resultWebAgentList;
      setAgentNames(Agents);
    } catch (error) {
      console.error("Error fetching agent names:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchPartyNames = async () => {
    setShowLoader(true);
    try {
      const requestBody1 = {
        LogID: LogId,
        FormKey: FormKey,
        Depo: DPCode,
        AgentName: "",
      };
      const response = await axios.post(
        `${BASE_URL}/parties`,
        requestBody1,
        config
      );
      const Parties = response.data.resultWebPartyList;
      setPartyNames(Parties);
    } catch (error) {
      console.error("Error fetching party names:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setShowLoader(true);
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedStartDate = `${year}${month}${day}`;

    const AgentPartyCode = filter === "Agentwise" ? 0 : 1;
    const Value = dropdown2 === "All" ? "" : dropdown2;

    try {
      const requestBody = {
        LogID: LogId,
        Depo: DPCode,
        OutstandingIndex: 0,
        SelectedIndex: AgentPartyCode,
        Selectedvalue: Value,
        StartDate: formattedStartDate,
      };

      const response = await axios.post(
        `${BASE_URL}/outstandingBill`,
        requestBody,
        config
      );
      const ReceivableBills = response.data.AllOutstandingBills;

      if (ReceivableBills.length === 0) {
        alert("No receivable bills found.");
        setRows([]);
      } else {
        // Group and format the data
        setRows(groupByCompany(ReceivableBills));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Your token is expired. Please log in again.");
        navigate("/login");
      } else {
        alert("Something went wrong. Please try again.");
      }
      console.error("Error:", error.message);
    } finally {
      setShowLoader(false);
    }
  };

  const groupByCompany = (data) => {
    const groupedData = data.reduce((acc, row) => {
      const companyName = row.LName;

      if (!acc[companyName]) {
        acc[companyName] = [];
      }
      acc[companyName].push(row);
      return acc;
    }, {});

    const groupedWithTotals = Object.keys(groupedData).map((companyName) => {
      const rows = groupedData[companyName];
      const totalDebit = rows.reduce(
        (sum, row) => sum + parseFloat(row.DrAmt || 0),
        0
      );
      const totalCredit = rows.reduce(
        (sum, row) => sum + parseFloat(row.CrAmt || 0),
        0
      );
      const totalBalance = totalDebit - totalCredit;

      // Add total row for the company
      return [
        ...rows,
        {
          sNo: "",
          BillRemark: "",
          TrDate: "",
          AName: "",
          LName: `Total`,
          DrAmt: totalDebit.toFixed(2),
          CrAmt: totalCredit.toFixed(2),
          Balance: totalBalance.toFixed(2),
          PDays: "",
          isTotalRow: true,
        },
      ];
    });

    return groupedWithTotals.flat();
  };

  useEffect(() => {
    document.title = "PTAC ERP || Receivable Bills";

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500); // Set loader to false after 1500 milliseconds

    return () => clearTimeout(timer);
  }, []);

  // Object to store grouped data
  const groupedData = {};
  // Group data by party name
  rows.forEach((row) => {
    if (!groupedData[row.LName]) {
      groupedData[row.LName] = [];
    }
    groupedData[row.LName].push(row);
  });

  let formattedRows = [];
  let totalRowIndex = 0;
  Object.keys(groupedData).forEach((partyName) => {
    let totalDebit = 0;
    let grandDebit = 0;
    let totalCredit = 0;
    let grandCredit = 0;
    let totalBalance = 0;
    let grandBalance = 0;
    formattedRows.push(...groupedData[partyName]);
    groupedData[partyName].forEach((row) => {
      totalDebit = row.DrAmt;
      totalCredit = row.CrAmt;

      grandDebit += row.DrAmt;
      grandCredit += row.CrAmt;

      grandBalance = grandDebit - grandCredit;
      row.Balance = totalDebit - totalCredit;
      totalBalance = row.Balance;
    });

    // formattedRows.push({
    //   id: `Party_${totalRowIndex}`,
    //   BillNo: `Party_${totalRowIndex}`,
    //   TrDate: "",
    //   AName: "",
    //   LName: "Grand Total",
    //   DrAmt: grandDebit,
    //   CrAmt: grandCredit,
    //   Balance: Math.abs(grandBalance),
    //   PDays: "",
    //   rowStyle: { backgroundColor: "red" },
    // });
    totalRowIndex++;
  });

  //===============================

  const handleDownloadAsPDF = () => {
    // Initialize jsPDF
    const doc = new jsPDF({
      orientation: "portrait",
    });

    // Define table headers
    const headers = columns
      .filter((column) => {
        // Conditionally remove "Agent Name" and "Party Name" if filter is "partywise" and dropdown2 !== "All"
        if (filter === "Partywise" && dropdown2 !== "All") {
          return (
            column.Header !== "Agent Name" &&
            column.Header !== "Party Name" &&
            column.Header !== "S No"
          );
        }
        // Otherwise, include all columns
        return column.Header !== "S No";
      })
      .map((column, index) => {
        // Change the first two headers to "No" and "Date"
        if (index === 0) return "No"; // First column header
        if (index === 1) return "Date"; // Second column header
        return column.Header; // Return the rest of the headers unchanged
      });

    // Define table data with conditional inclusion of "Agent Name" and "Party Name"
    const data = rows.map((row) => [
      row.BillRemark, // Doc No
      row.TrDate, // Doc Date
      ...(filter !== "Partywise" || dropdown2 === "All"
        ? [row.AName, row.LName] // Include Agent Name and Party Name when condition allows
        : []),
      row.DrAmt ? parseFloat(row.DrAmt).toFixed(2) : "0.00", // Debit
      row.CrAmt ? parseFloat(row.CrAmt).toFixed(2) : "0.00", // Credit
      row.Balance ? Math.abs(parseFloat(row.Balance)).toFixed(2) : "0.00", // Balance
      row.PDays, // Days
    ]);

    // Formatting the title and date information
    const title = "Bills Outstanding [Receivable]";
    const companyName = localStorage.getItem("cName");
    const companyLocation = localStorage.getItem("selectedLocation");
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${day}-${month}-${year}`;

    // Add the title and company info to the PDF
    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15;
    doc.setFont("helvetica", "semibold");
    doc.setFontSize(14);
    doc.text(title, center, 30, { align: "center" });
    doc.setFont("helvetica", "semibold"); // Set the font to bold variant of Helvetica
    doc.setFontSize(20);
    doc.text(`${companyName} ${companyLocation}`, center, 20, {
      align: "center",
    });
    doc.setFontSize(14);
    doc.setFont("helvetica", "thin"); // Set the font to bold variant of Helvetica
    doc.text(dropdown2 === "All" ? "" : dropdown2, center, 35, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.text(`Date: ${formattedStartDate}`, rightPosition, 30, {
      align: "right",
    });

    // Add a line below the date range
    // doc.setLineWidth(0.5);
    // doc.line(center - 30, 22, center + 30, 22);
    const quarterHeight = doc.internal.pageSize.height / 8;

    const estimateTotalPages = (data) => {
      const pageHeight = doc.internal.pageSize.height;
      const margin = 25; // Top and bottom margins
      const headerHeight = 20; // Height of the header
      const rowHeight = 10; // Height of a single row in the table

      // Calculate the total content height
      const totalContentHeight =
        margin + headerHeight + data.length * rowHeight;

      // Calculate total pages needed, excluding the first page
      return Math.ceil(
        (totalContentHeight - pageHeight) / (pageHeight - margin)
      ); // Adjust for margins
    };
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    // Estimate the total pages based on the data
    const totalPages = estimateTotalPages(data);

    // Add table to PDF
    doc.autoTable({
      startY: quarterHeight,
      head: [headers],
      body: data,
      margin: { bottom: 40 }, // Adds a 30-unit margin between the table and footer
      styles: {
        cellPadding: 1,
        lineWidth: 0.1,
        lineColor: [0, 0, 0], // Black border
        halign: "center", // Center-align all headers
      },
      bodyStyles: {
        halign: "left", // Left-align rows by default
      },
      headStyles: {
        fillColor: [85, 85, 85], // Set header bg color to gray-600 (#555555)
        textColor: [255, 255, 255], // White text color for the header
        halign: "center", // Center-align headers
      },
      columnStyles: {
        0: { cellWidth: 30 }, // Set width of the first column to 100 px
      },
      didParseCell: (data) => {
        const column = data.column.dataKey;
        const row = data.row.raw;
        const rowIndex = data.row.index;
        const totalRows = data.table.body.length;
        if (data.section === "body") {
          const hasPDays = data.row.raw[5];
          console.log("P Days:", hasPDays > 0); // Logs the raw data of the last row

          if (rowIndex === totalRows - 1) {
            data.cell.styles.fillColor = [249, 250, 251]; // gray-50
            data.cell.styles.textColor = [0, 0, 0]; // gray-50
          } else {
            data.cell.styles.fillColor = [255, 255, 255]; // gray-50
            data.cell.styles.textColor = [0, 0, 0]; // gray-50
          }

          if (hasPDays > 180) {
            data.cell.styles.fillColor = [255, 99, 71]; // Red background (bg-red-400 equivalent)
            data.cell.styles.textColor = [0, 0, 0]; // Black text (text-black)
            data.cell.styles.fontStyle = "bold"; // Bold font
          } else if (hasPDays > 90) {
            data.cell.styles.fillColor = [255, 223, 70]; // Yellow background (bg-yellow-400 equivalent)
            data.cell.styles.textColor = [0, 0, 0]; // Black text (text-black)
            data.cell.styles.fontStyle = "normal"; // Semi-bold font
          } else if (hasPDays > 60) {
            data.cell.styles.fillColor = [224, 255, 255]; // Cyan background (bg-cyan-200 equivalent)
            data.cell.styles.textColor = [0, 0, 0]; // Black text (text-black)
            data.cell.styles.fontStyle = "normal"; // Normal font
          }
        }
        // Condition for aligning Debit, Credit, Balance columns based on filter and dropdown2
        if (filter === "Partywise" && dropdown2 !== "All") {
          if (column === 2 || column === 3 || column === 4 || column === 5) {
            data.cell.styles.halign = "right"; // Align Debit, Credit, and Balance to the right for Partywise + All
          }
        } else {
          if (column === 4 || column === 5 || column === 6) {
            data.cell.styles.halign = "right"; // Align Debit, Credit, and Balance to the right for other conditions
          }
        }
        // If row.LName is "Total", apply bg color to the entire row
        if (row[3] === "Total") {
          data.cell.styles.fillColor = [169, 169, 169]; // Set row bg color to gray-400 (#A9A9A9)
          data.cell.styles.textColor = [255, 255, 255]; // Set text color to white
        }
        if (rowIndex === totalRows - 1) {
          // Assuming 'Balance' is in column 4
          data.cell.styles.fontStyle = "bold"; // Set text to bold for the Balance column
        }
      },

      didDrawPage: (data) => {
        // Footer logic (unchanged)
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        // Footer title
        const footerTitle = "Bank Details";
        doc.text(footerTitle, 15, doc.internal.pageSize.height - 30);

        // Draw a horizontal line after the title
        doc.setLineWidth(0.5);
        doc.line(
          15,
          doc.internal.pageSize.height - 28,
          doc.internal.pageSize.width - 15,
          doc.internal.pageSize.height - 28
        );

        //This will replace the actual bank details comes from the server

        const bankAccount = bank[0].map((bank) => bank.Bank1);
        const bankName = bank[0].map((bank) => bank.Bank2);
        const bankIFSC = bank[0].map((bank) => bank.Bank3);
        const bankAddress = bank[0].map((bank) => bank.Bank4);

        // Footer details
        const bankDetails = [
          { label: "Bank Name", value: bankName[0] }, // Dynamic bank name
          { label: "Account No", value: bankAccount[0] }, // Dynamic account number
          { label: "IFSC Code", value: bankIFSC[0] }, // Dynamic IFSC code
          { label: "Bank Address", value: bankAddress[0] }, // Combined address
        ];

        const footerStartY = pageHeight - 23; // Footer's start position

        const leftColumnX = 15; // Left side alignment
        const rightColumnX = pageWidth / 2; // Center alignment
        const lineHeight = 6; // Line spacing for details

        bankDetails.forEach((detail, index) => {
          const xPos = index % 2 === 0 ? leftColumnX : rightColumnX; // Left or Right
          const yPos = footerStartY + Math.floor(index / 2) * lineHeight;
          doc.text(`${detail.label}: ${detail.value}`, xPos, yPos);
        });
        const footerText = `Page ${data.pageCount}`;
        const textWidth = doc.getTextWidth(footerText);
        const margin = 10; // Margin from bottom
        doc.text(
          footerText,
          doc.internal.pageSize.width - textWidth - margin,
          doc.internal.pageSize.height - margin
        );
      },
    });

    // Save PDF
    doc.save("Outstanding Rec. Bill.pdf");
  };

  const handleDropdownChange = (e) => {
    const newValue = e.target.value; // Get the new value from the dropdown
    if (dropdown2) {
      // Reset the first dropdown to "All" (empty)
      setDropdown2(newValue);
    }
    // Reset both dropdowns to their default values when the second dropdown changes
    setDropdown2(newValue); // Update second dropdown
    // setFilter("Agentwise"); // Reset first dropdown to "All" (default value)
  };

  const handleFilterChange = (e) => {
    // Reset both dropdowns to their default values when the first dropdown changes
    setFilter(e.target.value); // Update first dropdown
    setDropdown2(""); // Reset second dropdown to "All" (default value)
  };

  return (
    <div className='p-1 mx-auto bg-gray-50 rounded-lg shadow-md'>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-1 mb-1'>
        <h1 className='text-sm text-center font-bold uppercase'>
          {targetPage.FormName}
        </h1>
        <form onSubmit={handleSubmit} className=''>
          <div className='grid grid-cols-2 lg:grid-cols-3 space-x-2'>
            <Dropdown
              options={[
                { value: "", label: "Select Filter" },
                { value: "Agentwise", label: "Agentwise" },
                { value: "Partywise", label: "Partywise" },
              ]}
              value={filter}
              onChange={handleFilterChange}
              label='Filter'
              isDisabled={false}
            />
            <Dropdown
              options={dropdown2Options}
              value={dropdown2}
              onChange={handleDropdownChange}
              label='Agent/Party Name'
              isDisabled={false}
            />
            <div className='col-span-2 lg:col-span-1 flex justify-end mt-1 lg:mt-3'>
              <DynamicButton variant='primary' label='Show' />
            </div>
          </div>
        </form>
      </div>
      <Table columns={columns} data={rows} /> {/* Replace with actual data */}
      {rows.length > 0 && (
        <div className='p-2 w-full flex gap-2'>
          <button
            onClick={handleDownloadAsPDF}
            className='font-semibold text-white p-1 rounded-md bg-[#4FA9A7] w-full'
          >
            <MdGetApp
              size={20}
              className='m-auto text-red-600 hover:text-white-100'
            />
            Download Pdf
          </button>
        </div>
      )}
    </div>
  );
};

export default ReceivableBills;
