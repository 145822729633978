import React, { useEffect, useState } from "react";
import Loader from "../../utils/Common/Loader";
import axios from "axios";
import { api2 } from "../../utils/Common/apiURL";
import Cookies from "js-cookie";
import ViewButton from "../../utils/Common/ViewButton";
import { useNavigate } from "react-router-dom";

const PartyVerification = () => {
  let selectedLocation = localStorage.getItem("selectedLocation");
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let targetRoute = "Party Verification";
  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;
  const token = Cookies.get("token");
  var LogId;
  var DPCode;
  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  LogId = itemFromLocalStorage[0].LogID;

  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = api2;
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(true);
  const [partyNames, setPartyNames] = useState([]);
  const [selectedParty, setSelectedParty] = useState([]);
  const [formData, setFormData] = useState({
    address1: "",
    address2: "",
    address3: "",
    city: "",
    contactPerson: "",
    creditDays: "",
    creditLimit: "",
    district: "",
    email: "",
    gstin: "",
    mgName: "",
    mobile: "",
    mobileSMS: "",
    pan: "",
    pCode: "",
    pName: "",
    partyCategory: "",
    partyName: "",
    pincode: "",
    regType: "",
    state: "",
  });
  const [partyData, setPartyData] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const handlePartyCodeChange = (e) => {
    const selectedPartyCode = e.target.value;
    setFormData({ ...formData, partyCode: selectedPartyCode });

    const selectedParty = partyData.find(
      (party) => party.PartyName === selectedPartyCode
    );
    setSelectedParty(selectedParty);
    if (selectedParty) {
      setFormData({
        ...formData,
        ...selectedParty,
        country: "INDIA",
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form data to table
    setShowLoader(true);
    console.log("Party Data:", selectedParty);
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);
    alert("Party verified successfully!");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestBody = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
          AgentName: "",
        };
        const response = await axios.post(
          `${BASE_URL}/parties`,
          requestBody,
          config
        );
        console.log(response.data);
        const partyNamesData = response.data.resultWebPartyList.map(
          (row) => row.LName
        );
        const uniquePartyNames = Array.from(new Set(partyNamesData));
        setPartyNames(uniquePartyNames);
        setPartyData(response.data.resultWebPartyList);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response.status === 401) {
          alert("Your Token is expired Please Login Again!!!");
        } else {
          alert("Something went wrong!!!");
        }
      }
    };
    fetchData();
  }, []);

  const parties = [
    ...partyNames.map((partyName, index) => ({
      value: partyName,
      label: partyName,
    })),
  ];

  return (
    <div className='p-1 mx-auto bg-gray-50 rounded-lg shadow-md'>
      {showLoader && <Loader />}
      <div className=''>
        <h1 className='text-sm text-center font-bold uppercase'>
          {targetPage.FormName}
        </h1>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 gap-2 mb-7'>
            <div className='flex gap-5'>
              <div className='w-full'>
                <label className='text-sm text-black font-semibold mb-1 block'>
                  Party Name
                </label>
                <select
                  name='unverifiedParty'
                  className='p-1 border rounded-md outline-none w-full'
                  value={formData.partyCode}
                  onChange={handlePartyCodeChange}
                  required
                >
                  <option value=''>Select Party Name</option>
                  {parties.map((party, index) => (
                    <option key={index} value={party.partyCode}>
                      {party.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-5 gap-2 mb-7'>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Address1
              </label>
              <input
                type='text'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='address1'
                onChange={handleInputChange}
                readOnly
                value={selectedParty.Address1}
                required
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Address2
              </label>
              <input
                type='text'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='address2'
                value={selectedParty.Address2}
                onChange={handleInputChange}
                readOnly
                required
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Address3
              </label>
              <input
                type='text'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='address3'
                onChange={handleInputChange}
                readOnly
                value={selectedParty.Address3 ? selectedParty.Address3 : "-"}
                required
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                City
              </label>
              <input
                type='text'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='city'
                value={selectedParty.City}
                onChange={handleInputChange}
                readOnly
                required
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Contact Person
              </label>
              <input
                type='text'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='contactPerson'
                value={
                  selectedParty.ContactPerson
                    ? selectedParty.ContactPerson
                    : "-"
                }
                onChange={handleInputChange}
                readOnly
                required
              />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-5 gap-2 mb-7'>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Credit Days
              </label>
              <input
                type='number'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='creditDays'
                value={selectedParty.CreditDays}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Credit Limit
              </label>
              <input
                type='number'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='creditLimit'
                value={selectedParty.CreditLimit}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                District
              </label>
              <input
                type='text'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                name='district'
                value={selectedParty.District ? selectedParty.District : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Email
              </label>
              <input
                type='email'
                name='email'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.Email ? selectedParty.Email : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                GSTIN
              </label>
              <input
                type='text'
                name='gstin'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.GSTIN}
                readOnly
              />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-5 gap-2 mb-7'>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                MG Name
              </label>
              <input
                type='text'
                name='mgName'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.MGName}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Mobile
              </label>
              <input
                type='text'
                name='mobile'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.Mobile ? selectedParty.Mobile : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Mobile SMS
              </label>
              <input
                type='text'
                name='mobileSMS'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.MobileSMS ? selectedParty.MobileSMS : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                PAN
              </label>
              <input
                type='text'
                name='pan'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.PAN ? selectedParty.PAN : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                P Code
              </label>
              <input
                type='text'
                name='pCode'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.PCode ? selectedParty.PCode : "-"}
                readOnly
              />
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-5 gap-2 mb-7'>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                P Name
              </label>
              <input
                type='text'
                name='pName'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.PName ? selectedParty.PName : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Party Category
              </label>
              <input
                type='number'
                name='partyCategory'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.PartyCategory}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Pincode
              </label>
              <input
                type='text'
                name='pincode'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.Pincode ? selectedParty.Pincode : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                Reg Type
              </label>
              <input
                type='text'
                name='regType'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.RegType ? selectedParty.RegType : "-"}
                readOnly
              />
            </div>
            <div>
              <label className='text-sm text-black font-semibold mb-1 block'>
                State
              </label>
              <input
                type='text'
                name='state'
                className='border border-gray-300 rounded-md px-2 py-1 w-full'
                value={selectedParty.State ? selectedParty.State : "-"}
                readOnly
              />
            </div>
          </div>
          <div className='grid grid-cols-1 p-1'>
            <ViewButton onClick={handleSubmit} label='Verify' />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PartyVerification;
