import React, { useState, useRef, useEffect } from "react";
import { FaArrowDown, FaTimes } from "react-icons/fa"; // For arrow and close icons

const InputDropdown = ({
  label,
  options = [],
  value,
  onChange,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Update filtered options based on input value
    setFilteredOptions(
      inputValue
        ? options.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
          )
        : options
    );
  }, [inputValue, options]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsOpen(true); // Open the popup when input changes
  };

  const handleOptionClick = (option) => {
    setInputValue(option); // Set the selected option to the inputValue
    setIsOpen(false); // Close the popup
    onChange(option); // Pass the selected option directly
  };

  const handleInputFocus = () => {
    setIsOpen(true); // Open popup on input focus
    setInputValue(""); // Clear the input field in the popup when it opens
  };

  return (
    <div className='relative flex flex-col cursor-pointer' ref={dropdownRef}>
      <label className='text-sm font-medium mb-1'>{label}</label>
      <div className='relative'>
        <input
          type='text'
          value={inputValue} // This shows the current value (not the selected value)
          onFocus={handleInputFocus}
          className='w-full p-2 pl-3 pr-10 border border-gray-300 bg-gray-100 font-semibold text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
          placeholder='Type to search...'
          disabled={isDisabled}
          readOnly // Lock input from typing but still clickable
        />
        <div
          className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer'
          onClick={() => setIsOpen(!isOpen)}
        >
          <FaArrowDown size={16} />
        </div>
      </div>

      {isOpen && (
        <>
          {/* Overlay to cover the rest of the screen */}
          <div
            className='fixed inset-0 bg-black bg-opacity-50 z-30'
            onClick={() => setIsOpen(false)}
          ></div>

          {/* Centered Popup Panel */}
          <div className='fixed inset-0 flex items-center justify-center z-40'>
            <div className='bg-white w-96 p-4 rounded-lg shadow-lg max-h-[80vh] overflow-y-auto relative'>
              {/* Close button at the top-right corner */}
              <div
                className='absolute top-2 right-2 text-gray-500 cursor-pointer'
                onClick={() => setIsOpen(false)}
              >
                <FaTimes size={20} />
              </div>

              <input
                type='text'
                value={inputValue} // Retaining the input value, which will be cleared initially
                onChange={handleInputChange} // Update the input value as user types in the search box
                className='w-full p-2 mt-5 mb-4 border border-gray-300 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                placeholder='Search...'
              />
              {filteredOptions.length === 0 && inputValue ? (
                <div className='p-2 text-gray-500'>No options available</div>
              ) : (
                filteredOptions.map((option, index) => (
                  <div
                    key={index}
                    className='p-2 hover:bg-blue-500 hover:text-white cursor-pointer'
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InputDropdown;
