import React, { useState, useEffect } from "react";
import Table from "../../components/Datatable/SearchFilterTable";
import DynamicDatePicker from "../../components/DynamicComponents/DynamicDatePicker";
import DynamicInput from "../../components/DynamicComponents/DynamicInput";
import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import Loader from "../../utils/Loader";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URI } from "../../utils/BaseURL";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    Header: "Date",
    accessor: "TranDate",
    Cell: ({ value }) => (
      <div style={{ textAlign: "center" }}>
        {new Date(value).toLocaleDateString("en-IN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Day",
    id: "day",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {new Date(row.original.TranDate).toLocaleDateString("en-IN", {
          weekday: "long",
        })}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "User Name",
    accessor: "UserName",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: true,
  },
  {
    Header: "Rate",
    accessor: "Rate",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}> {value?.toFixed(2)}</div>
    ),
    isInitiallyVisible: true,
  },
];

const RateChart = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [todayRate, setTodayRate] = useState("");
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const token = Cookies.get("token");
  const BASE_URL = BASE_URI;

  const menuValue = JSON.parse(localStorage.getItem("Menu"));
  const Depot = JSON.parse(localStorage.getItem("Depo"));
  const selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();
  const targetPage = menuValue.find((item) => item.FormName === "Rate Chart");
  const FormKey = targetPage ? targetPage.FormKey : null;

  const LogId = JSON.parse(localStorage.getItem("userInfo"))[0].LogID;
  const DPCode = Depot.find(
    (depot) => depot.DPName === selectedLocation
  )?.DPCode;

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate rate input
    if (!todayRate) {
      alert("Please enter today's rate.");
      return;
    }

    setShowLoader(true);

    const rateData = {
      username: LogId,
      date: selectedDate.toISOString().slice(0, 10).replace(/-/g, ""),
      rate: todayRate,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/oilRateForm/oil-rate`,
        rateData,
        config
      );

      if (response.status === 201) {
        // setRows((prevRows) => [...prevRows, rateData]); // Add valid data
        setTodayRate(""); // Clear today's rate input
        alert("Today's Rate updated successfully!");
      } else {
        alert("Failed to update rate.");
      }
    } catch (error) {
      console.error("Error submitting rate:", error);
      alert("An error occurred while submitting the rate.");
    } finally {
      setShowLoader(false);
      fetchRateData(); // Refetch data to ensure the table is up to date
    }
  };

  const fetchRateData = async () => {
    setShowLoader(true);
    try {
      const requestBody = {
        LogID: LogId,
        FormKey: FormKey,
        Depo: DPCode,
        PartyName: "",
        AgentName: "",
        ItemGroup: "",
        ItemName: "",
        Date1: "20240707",
        Date2: "20240707",
        RptCode: 0,
      };

      const response = await axios.post(
        `${BASE_URL}/web-reports`,
        requestBody,
        config
      );
      if (response.status === 200) {
        setRows(response.data.results);

        setTodayRate(
          response.data.results.length > 0 ? response.data.results[0].Rate : ""
        ); // Set today's rate
        setShowLoader(false);
      } else {
        alert("Failed to fetch rates data.");
      }
    } catch (error) {
      console.error("Error fetching rates data:", error);
      alert("An error occurred while fetching the rates data.");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchRateData(); // Call the function to fetch data on mount
  }, []);

  return (
    <div className='p-1 mx-auto bg-gray-50 rounded-lg shadow-md'>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-1 mb-1'>
        <h1 className='text-sm uppercase text-center font-bold'>Rate Chart</h1>
        <form onSubmit={handleSubmit} className=''>
          <div className='grid grid-cols-3 lg:grid-cols-3 gap-2'>
            <DynamicDatePicker
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
              maxDate={new Date()}
              minDate={new Date()}
              dateFormat='dd/MM/yyyy'
              DateLabel='Select Date'
            />
            <DynamicInput
              label="Today's Rate"
              value={todayRate}
              onChange={(e) => setTodayRate(e.target.value)}
              type='number'
              placeholder="Enter today's rate"
            />
            <div className='col-span-1 lg:col-span-1 flex justify-end mt-3'>
              <DynamicButton variant='primary' label='Update Rate' />
            </div>
          </div>
        </form>
      </div>
      <Table columns={columns} data={rows} />
    </div>
  );
};

export default RateChart;
