import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for fetching data
import Cookies from "js-cookie";
import DropDown from "../../utils/Common/Dropdown";
import SearchDropDown from "../../utils/Common/SearchableDropdown";
import { FaEdit, FaTrash } from "react-icons/fa";
import EditModal from "./SaleOrderModal";
import { api1, api2 } from "../../utils/Common/apiURL";
import DynamicTable from "../../utils/Common/NewDataTable";
import AddButton from "../../utils/Common/InputButton";
import { useNavigate } from "react-router-dom";
import Loader from "../../utils/Common/Loader";
import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import Table from "../../components/Datatable/SearchFilterTable";

const generateShortAlphanumericId = () => {
  const alphanumericCharacters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let shortId = "";
  for (let i = 0; i < 11; i++) {
    shortId += alphanumericCharacters.charAt(
      Math.floor(Math.random() * alphanumericCharacters.length)
    );
  }
  return shortId;
};

function SaleOrder() {
  const [id, setId] = useState(0);
  const [orderNumber, setOrderNumber] = useState(0);
  const [orderId, setOrderId] = useState(generateShortAlphanumericId());
  const [orderDate, setOrderDate] = useState(
    new Date().toLocaleDateString("en-GB").split("-").reverse().join("-")
  );
  const [partyName, setPartyName] = useState("");
  const [itemGroup, setItemGroup] = useState("");
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [rate, setRate] = useState("");
  const [packing, setPacking] = useState("");
  const [MRP, setMRP] = useState("");
  const [unit, setUnit] = useState("");
  const [amount, setAmount] = useState("");
  const [GST, setGST] = useState("");
  const [dropdown1Value, setDropdown1Value] = useState("");
  const [dropdown2Value, setDropdown2Value] = useState("");
  const [partyNames, setPartyNames] = useState([]);
  const [itemNames, setitemNames] = useState([]);
  const [displayItem, setDisplayItem] = useState([]);
  const [partyCode, setPartyCode] = useState("");
  const [rows, setRows] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedRowData, setEditedRowData] = useState({});
  const [percentage, setPercentage] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [afterDiscount, setAfterDiscount] = useState("");
  // Define states for TDS
  const [tdsPercentage, setTdsPercentage] = useState("");
  const [tdsAmount, setTdsAmount] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // Define states for SD
  const [sdPercentage, setSdPercentage] = useState("");
  const [sdAmount, setSdAmount] = useState("");

  const [taxableValue, setTaxableValue] = useState("");

  var FinancialYear = localStorage.getItem("FinancialYear");

  // Basic Information For API's
  let targetRoute = "Sale Order";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;
  const token = Cookies.get("token");
  var LogId;
  var DPCode;
  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  LogId = itemFromLocalStorage[0].LogID;

  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = api2;

  useEffect(() => {
    fetchData();
  }, [dropdown1Value]);

  const fetchData = async () => {
    setShowLoader(true);
    try {
      const requestBody1 = {
        LogID: LogId,
        FormKey: FormKey,
        Depo: DPCode,
        AgentName: "",
      };
      const response1 = await axios.post(
        `${BASE_URL}/parties`,
        requestBody1,
        config
      );
      const partyNamesData = response1.data.resultWebPartyList.map(
        (row) => row.LName
      );
      const uniquePartyNames = Array.from(new Set(partyNamesData));
      setPartyNames(uniquePartyNames);

      const matchedData = response1.data.resultWebPartyList.find((row) => {
        return row.LName === dropdown1Value;
      });
      console.log(matchedData);
      if (matchedData) {
        // Change
        const partyCode = matchedData.LCode;
        const name = matchedData.LName;
        setPartyName(name);
        setPartyCode(partyCode);
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 401) {
        alert("Your Token is expired Please Login Again!!!");
        navigate("/login");
      } else {
        alert("Something went wrong!!!");
      }
      setShowLoader(false);
    }
  };
  useEffect(() => {
    if (partyCode) {
      try {
        const fetchPriceData = async () => {
          const requestBody = {
            LogID: LogId,
            Depo: DPCode,
            PartyCode: partyCode,
          };
          const itemPrices = await axios.post(
            `${api2}/itemLists/items-price`,
            requestBody,
            config
          );
          setitemNames(itemPrices.data.AllItemsData);
        };

        fetchPriceData();
      } catch (error) {
        if (error.response.status === 401) {
          alert("Your Token is expired Please Login Again!!!");
          navigate("/login");
        } else {
          alert("Something went wrong!!!");
        }
      }
    }
  }, [dropdown1Value, partyCode]);

  const columns = [
    {
      accessor: "actions",
      Header: "Actions",
      Cell: ({ row }) =>
        row.original.itemGroup !== "Grand Total" ? (
          <div className='flex mt-2 justify-center p-1'>
            {/* Edit icon */}
            <FaEdit
              size={20}
              onClick={() => handleEdit(row)}
              style={{ cursor: "pointer", marginRight: "10px", color: "blue" }}
            />
            {/* Delete icon */}
            <FaTrash
              size={20}
              onClick={() => handleDelete(row.original.id)}
              style={{ cursor: "pointer", color: "red" }}
            />
          </div>
        ) : (
          <div className='flex mt-2'>
            <button
              onClick={() => handleFinalSubmit()}
              className='bg-green-600 rounded-lg px-5 py-2 -mt-[8px] text-white font-semibold'
            >
              Save
            </button>
          </div>
        ),
      isInitiallyVisible: true,
    },
    {
      accessor: "id",
      Header: "S No",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "orderDate",
      Header: "Date",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "itemGroup",
      Header: "Item Name",
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "quantity",
      Header: "Quantity",
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "unit",
      Header: "Unit",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "packing",
      Header: "Packing",
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
      isInitiallyVisible: false,
    },
    {
      accessor: "MRP",
      Header: "MRP",
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {value ? parseFloat(value).toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: false,
    },
    {
      accessor: "MRPDisc",
      Header: "MRP Disc %",
      align: "right",
      headerAlign: "center",
      Cell: ({ value, row }) => (
        <div style={{ textAlign: "right" }}>
          {row.original.itemGroup === "Grand Total"
            ? value
            : `${value.toFixed(2)}`}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      accessor: "rate",
      Header: "Price",
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "amount",
      Header: "Amount",
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
      isInitiallyVisible: true,
    },

    {
      accessor: "tdsAmount",
      Header: "TD %",
      align: "right",
      headerAlign: "center",
      Cell: ({ value, row }) => (
        <div style={{ textAlign: "right" }}>
          {row.original.itemGroup === "Grand Total"
            ? value
            : `${value.toFixed(2)}`}
        </div>
      ),
      isInitiallyVisible: false,
    },
    // {
    //   accessor: 'sdAmount',
    //   Header: 'SD %',
    //   Cell: ({ value,row }) => (
    //     <div style={{ width: "50px", textAlign: "right" }}>
    //     {row.original.itemGroup === 'Grand Total' ? value : `${value.toFixed(2)}`}
    //     </div>
    //   ),
    // },
    {
      accessor: "netTaxableValue",
      Header: "Taxable Value",
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "GST",
      Header: "GST",
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      accessor: "totalAmt",
      Header: "Total Amount",
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
      isInitiallyVisible: true,
    },
  ];

  const handleEdit = (row) => {
    console.log(row.original);
    setEditedRowData(row);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  const handleSubmit = () => {
    if (quantity === "") {
      alert("Enter Quantity");
    } else {
      const newItem = {
        id: id + 1,
        orderDate,
        partyName: dropdown1Value,
        itemGroup: dropdown2Value,
        quantity,
        rate: calculateRate(),
        unit: displayItem.UName,
        packing: displayItem.Packing,
        MRP: displayItem.MRP,
        MRPDisc: displayItem.MRPDisc,
        amount: calculateAmount(),
        tdsAmount: displayItem.TD,
        sdAmount: displayItem.SD,
        netTaxableValue: calculateTaxableValue(),
        GST: displayItem.GSTName,
        totalAmt: calculateNetTotal(),
      };
      console.log(newItem);

      setRows([...rows, newItem]);

      setId(id + 1);
      setQuantity("");
      // setDisplayItem([])
      setDropdown2Value("");
      // setitemNames([])
    }
  };

  const handleFinalSubmit = async () => {
    // Your submit logic here
    console.log("Data in the table:", rowsToShow);
    const uniqueToken = generateShortAlphanumericId();

    const rowsToSubmit = rowsToShow.slice(0, -1);
    // console.log(Depo,uniqueToken, LogID)

    const tableData = {
      Depo: DPCode,
      TokenNo: uniqueToken,
      LogID: LogId,
      Items: rowsToSubmit.map((row, index) => ({
        TokenNo: uniqueToken,
        Depo: DPCode,
        PartyName: dropdown1Value,
        ItemName: row.itemGroup,
        Qty: row.quantity,
        MRP: row.MRP,
        MRPDisc: row.MRPDisc,
        Price: row.rate,
        Amount: row.amount,
        TD: row.tdsAmount,
        SD: row.sdAmount,
        Remark: "",
        Sno: index + 1,
      })),
    };
    const response = await axios.post(
      `${BASE_URL}/saleOrder`,
      tableData,
      config
    );
    alert("Sale Order created Successfully!!!");
    // Handle response if needed
    console.log("Response from server:", response.data);
    setRows([]);
    // setDropdown1Value('');
    setDropdown2Value("");
    setitemNames([]);
  };

  const handleSaveChanges = (updatedData) => {
    // Implement logic to save changes to the row data
    console.log("Edited data:", updatedData);

    console.log(rows);
    console.log(updatedData.id);
    const rowIndex = rows.findIndex((row) => row.id === updatedData.id);
    console.log(rowIndex);
    if (rowIndex !== -1) {
      // Update the row data with the edited data
      const updatedRows = [...rows];
      updatedRows[rowIndex] = { ...updatedRows[rowIndex], ...updatedData };
      // Update the state with the new row data
      setRows(updatedRows);
    }
    // // Close the modal
    setEditModalOpen(false);
  };

  const handleDelete = (id) => {
    // Filter out the row with the given ID
    const updatedRows = rows.filter((row) => row.id !== id);

    // Update the state with the filtered rows
    setRows(updatedRows);
  };

  const handleDropdown1Change = (e) => {
    setDropdown1Value(e.target.value);
    setDropdown2Value("");
  };

  const handleDropdown2Change = (e) => {
    setDropdown2Value(e.target.value);
  };

  const items = itemNames
    ? itemNames.map((item) => ({
        value: item.IName,
        label: item.IName,
      }))
    : [];
  items.unshift({ value: "", label: "Select Item Name" });
  const selectedRow = itemNames.find((item) => item.IName === dropdown2Value);

  useEffect(() => {
    setDisplayItem(selectedRow);
  }, [selectedRow]);

  const price = [{ value: "", label: "Select Price" }];

  const parties = [
    { value: "", label: "Select Party Name" },
    ...partyNames.map((partyName, index) => ({
      value: partyName,
      label: partyName,
    })),
  ];

  const handleNewOrder = () => {
    console.log("Check");
    setOrderNumber(orderNumber + 1);
    setOrderId(generateShortAlphanumericId());
    setRows([]);
  };

  const handlePercentageChange = (e) => {
    const newPercentage = e.target.value;
    setPercentage(newPercentage);
    const amount = rate * quantity;
    const newTotalDiscount = rate * quantity * (newPercentage / 100);
    var afterDiscount = amount - newTotalDiscount;
    setAfterDiscount(afterDiscount);
    setTotalDiscount(newTotalDiscount);
  };

  const handleTDSPercentageChange = (e) => {
    var remainingAmount = rate * quantity - totalDiscount;
    const tdsPercentage = e.target.value;
    setTdsPercentage(tdsPercentage);
    const newTotalDiscount = remainingAmount * (tdsPercentage / 100);
    var afterTDSDiscount = remainingAmount - newTotalDiscount;
    setAfterDiscount(afterTDSDiscount);
    setTdsAmount(newTotalDiscount);
  };

  const handleSDPercentageChange = (e) => {
    const sdPercentage = e.target.value;
    setSdPercentage(sdPercentage);
    const newTotalDiscount = afterDiscount * (sdPercentage / 100);
    var afterSDDiscount = afterDiscount - newTotalDiscount;
    setSdAmount(newTotalDiscount);
    setAmount(afterSDDiscount);
  };

  const calculateGSTValue = () => {
    if (!displayItem || !displayItem.GSTName) return ""; // Return empty string if displayItem or GSTName is undefined

    const gstPercentage = displayItem.GSTName.split(" ")[1].replace("%", "");
    const mrp = displayItem.MRP;
    const mrpDisc = displayItem.MRPDisc;
    // const quantity = 1; // Assuming quantity is 1 for now, adjust if necessary
    const td = displayItem.TD;
    const sd = displayItem.SD;

    const gstValue = (calculateTaxableValue() * gstPercentage) / 100;
    return gstValue.toFixed(2);
  };

  const calculateNetTotal = () => {
    if (!displayItem || !displayItem.GSTName) return ""; // Return empty string if displayItem or GSTName is undefined

    const gstPercentage = displayItem.GSTName.split(" ")[1].replace("%", "");
    const mrp = displayItem.MRP;
    const mrpDisc = displayItem.MRPDisc;
    const td = displayItem.TD;
    const sd = displayItem.SD;
    const netTotal =
      parseFloat(calculateTaxableValue()) + parseFloat(calculateGSTValue());

    return netTotal.toFixed(2);
  };

  const calculateTaxableValue = () => {
    if (!displayItem) return ""; // Return empty string if displayItem is undefined

    const mrp = displayItem.MRP;
    const mrpDiscPercentage = displayItem.MRPDisc;
    const tdPercentage = displayItem.TD;
    const sdPercentage = displayItem.SD;
    // const quantity = 1; // Assuming quantity is 1 for now, adjust if necessary

    // Calculate discounted MRP
    const discountedMRP = mrp - mrp * (mrpDiscPercentage / 100);

    // Calculate Taxable Amount after discount
    const taxableAmountAfterDiscount = discountedMRP * quantity;

    // Calculate Taxable Amount after TD
    const taxableAmountAfterTD =
      taxableAmountAfterDiscount -
      (taxableAmountAfterDiscount * (tdPercentage / 100)).toFixed(2);

    const taxableAmountAfterSD =
      taxableAmountAfterTD - taxableAmountAfterTD * (sdPercentage / 100);

    // Return the calculated value with 2 decimal places
    return taxableAmountAfterSD.toFixed(2);
  };

  const calculateRate = () => {
    if (!displayItem) return "";
    const price =
      displayItem.MRP - displayItem.MRP * (displayItem.MRPDisc / 100);
    return price.toFixed(2);
  };

  const calculateAmount = () => {
    if (!displayItem) return "";
    const price =
      displayItem.MRP - displayItem.MRP * (displayItem.MRPDisc / 100);
    return (price * quantity).toFixed(2);
  };

  const grandTotal = rows.reduce(
    (total, row) => total + parseFloat(row.totalAmt),
    0
  );

  let grandTotalRow = {
    id: "",
    MRPDisc: "", // Keep it empty for consistency
    itemGroup: "Grand Total",
    totalAmt: grandTotal.toFixed(2), // Format amount with two decimal places
    actions: "_", // Empty actions column for the grand total row
  };

  const rowsToShow = grandTotal > 0 ? [...rows, grandTotalRow] : rows;

  const allItems = itemNames;
  useEffect(() => {
    document.title = "PTAC ERP || Sale Order";

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500); // Set loader to false after 1500 milliseconds

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className='p-1 mx-auto bg-gray-50 rounded-lg shadow-md'>
      {showLoader && <Loader />}
      <div className=''>
        <h1 className='text-sm text-center font-bold uppercase '>
          {targetPage.FormName}
        </h1>
        {/* Form for adding new item */}
        <div className=''>
          {/* Input fields for new item */}
          <div className='grid grid-cols-2 gap-1 bg-white shadow-md p-1 mt-1 rounded-lg mb-2'>
            <div className=''>
              <DropDown
                id='dropdown1'
                value={dropdown1Value}
                onChange={handleDropdown1Change}
                options={parties}
                label='Party Name'
              />
            </div>
            <div>
              <SearchDropDown
                id='dropdown2'
                value={dropdown2Value}
                onChange={handleDropdown2Change}
                options={items}
                label='Item name'
              />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-1'>
            <div className='flex'>
              <div className='flex-1 mr-2'>
                <label htmlFor='quantity' className='block'>
                  Quantity:
                </label>
                <input
                  type='number'
                  id='quantity'
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                />
              </div>
              <div className='flex-1 mr-2'>
                <label htmlFor='unit' className='block'>
                  Unit:
                </label>
                <input
                  type='text'
                  id='unit'
                  value={displayItem ? displayItem.UName : ""}
                  onChange={(e) => setUnit(e.target.value)}
                  className='border border-gray-300 rounded-md px-2 py-1 w-full'
                  readOnly
                />
              </div>
            </div>

            <div>
              <label htmlFor='packing' className='block'>
                Packing:
              </label>
              <input
                type='text'
                id='packing'
                value={displayItem ? displayItem.Packing : ""}
                onChange={(e) => setPacking(e.target.value)}
                className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                readOnly
              />
            </div>
            <div className='flex'>
              <div className='flex-1 mr-2'>
                <label htmlFor='MRP' className='block'>
                  MRP:
                </label>
                <input
                  type='number'
                  id='MRP'
                  value={
                    displayItem
                      ? displayItem.MRP
                        ? displayItem.MRP.toFixed(2)
                        : ""
                      : ""
                  }
                  onChange={(e) => setMRP(e.target.value)}
                  className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                  readOnly
                />
              </div>
              <div className='flex-1 mr-2'>
                <label htmlFor='percentage' className='block'>
                  MRP Disc.:
                </label>
                <input
                  type='number'
                  id='percentage'
                  value={displayItem ? displayItem.MRPDisc : ""}
                  onChange={handlePercentageChange}
                  className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                  readOnly
                />
              </div>
            </div>
            <div className='flex'>
              <div className='flex-1 mr-2'>
                <label htmlFor='rate' className='block'>
                  Price:
                </label>
                <input
                  type='number'
                  id='rate'
                  value={calculateRate()}
                  // value={itemRate}
                  onChange={(e) => setRate(e.target.value)}
                  className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                  readOnly
                />
              </div>
              <div className='flex-1 mr-2'>
                <label htmlFor='amount' className='block'>
                  Amount:
                </label>
                <input
                  type='number'
                  id='amount'
                  value={calculateAmount()}
                  onChange={(e) => setAmount(e.target.value)}
                  className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-1'>
            <div className='flex'>
              <div className='flex-1 mr-2'>
                <label htmlFor='tdsAmount' className='block'>
                  TD (%):
                </label>
                <input
                  type='number'
                  id='tdsAmount'
                  value={displayItem ? displayItem.TD : ""}
                  onChange={(e) => setTdsAmount(e.target.value)}
                  className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                  readOnly
                />
              </div>
            </div>

            <div>
              <label htmlFor='amount' className='block'>
                {" "}
                Taxable Value:
              </label>
              <input
                type='number'
                id='amount'
                // value={displayItem ? ( ) : ''}
                value={calculateTaxableValue()}
                onChange={(e) => setAmount(e.target.value)}
                className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                readOnly
              />
            </div>

            <div className='flex-1 mr-2'>
              <label htmlFor='GSTValue' className='block'>
                {displayItem ? displayItem.GSTName : "GST"}:
              </label>
              <input
                type='text'
                id='GSTValue'
                value={calculateGSTValue()}
                onChange={(e) => setGST(e.target.value)}
                className='border border-gray-300 rounded-md px-2 py-1 w-full text-right'
                readOnly
              />
            </div>
            <div className='flex-1 mr-2'>
              <label htmlFor='totalAmount' className='block'>
                Total Amount:
              </label>
              <input
                type='text'
                id='totalAmount'
                value={calculateNetTotal()}
                onChange={(e) => setGST(e.target.value)}
                className='border border-gray-300 rounded-md px-2 py-1 w-full text-right font-bold text-xl'
                readOnly
              />
            </div>
          </div>
        </div>
        <div className='grid justify-end mt-2' onClick={handleSubmit}>
          <DynamicButton variant='primary' label='Add Item' />
        </div>

        {/* Display DataGrid with rows */}
        <div className=''>
          <div style={{ height: 400, width: "100%" }}>
            {/*<DynamicTable columns={columns} rows={rowsToShow} />*/}
            <Table columns={columns} data={rowsToShow} />
          </div>
        </div>

        {editModalOpen === true && (
          <EditModal
            rowData={editedRowData}
            itemData={allItems}
            open={editModalOpen}
            partyCode={partyCode}
            handleClose={handleCloseModal}
            handleSave={handleSaveChanges}
          />
        )}
      </div>
    </div>
  );
}

export default SaleOrder;
