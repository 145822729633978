import React, { useEffect, useRef, useState } from "react";
import {
  FaUserCircle,
  FaDatabase,
  FaExchangeAlt,
  FaChartBar,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { TbReportSearch } from "react-icons/tb";
import { MdAccountBalanceWallet } from "react-icons/md";

const iconData = [
  { icon: FaDatabase, title: "Master", id: "master", fGroup: 1 },
  { icon: FaExchangeAlt, title: "Transaction", id: "transaction", fGroup: 2 },
  { icon: FaChartBar, title: "Display", id: "display", fGroup: 3 },
  { icon: TbReportSearch, title: "Reports", id: "reports", fGroup: 4 },
  {
    icon: MdAccountBalanceWallet,
    title: "Accounts",
    id: "accounts",
    fGroup: 12,
  },
];

const Navbar = ({ onIconClick, darkMode }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const menu = JSON.parse(localStorage.getItem("Menu")) || [];
  const User = JSON.parse(localStorage.getItem("userInfo")) || [];
  const Logo = User[0]?.Logo;

  // Step 2: Group items by FGroup
  const groupedByFGroup = menu.reduce((acc, item) => {
    if (!acc[item.FGroup]) {
      acc[item.FGroup] = [];
    }
    acc[item.FGroup].push(item);
    return acc;
  }, {});

  // Step 3: Filter the iconData to only include icons with items in their respective FGroup
  const filteredIcons = iconData.filter(
    (icon) => groupedByFGroup[icon.fGroup]?.length > 0
  );

  // Step 4: Set the first icon as the default selected icon
  const [selectedIcon, setSelectedIcon] = useState(
    filteredIcons.length > 0 ? filteredIcons[0].id : null
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    onIconClick(selectedIcon);
  }, [selectedIcon, onIconClick]);

  const handleIconClick = (id) => {
    setSelectedIcon(id);
  };

  return (
    <nav className='flex flex-col items-center h-screen cursor-pointer w-full'>
      {/* Profile Icon */}
      <div className='mb-4 h-1/4 mt-5'>
        <Link to='/dashboard/home'>
          <img src={Logo} alt='Logo' />
        </Link>
      </div>

      {/* Icons */}
      <div className='grid items-center h-2/4 w-full'>
        {filteredIcons.map(({ icon: Icon, title, id }, index) => (
          <div
            key={index}
            onClick={() => handleIconClick(id)}
            className={`relative group flex items-center justify-center p-2 m-1 ${
              selectedIcon === id ? "bg-gray-200 shadow-lg p-2 rounded-xl " : ""
            }`}
          >
            <div className='flex items-center justify-center'>
              <Icon
                className={`text-2xl transition-colors duration-200 ${
                  selectedIcon === id ? "text-gray-700" : "text-gray-500"
                }`}
              />
            </div>
            <div className='absolute bottom-4 left-10 transform -translate-x-1/2 -translate-y-full bg-gray-400 text-white text-xs rounded py-1 px-2 whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity'>
              {title}
            </div>
          </div>
        ))}
      </div>

      {/* Profile Dropdown */}
      <div className='flex flex-col items-center mt-4 fixed bottom-2'>
        <div className='relative'>
          <FaUserCircle
            className='text-gray-600 text-4xl cursor-pointer'
            onClick={() => setDropdownOpen(!dropdownOpen)}
          />
          <div className='absolute top-0 right-0 h-3 w-3 bg-green-500 rounded-full border-2 border-white'></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
