import React, { useState, useEffect } from "react";
import Table from "../../components/Datatable/SearchFilterTable";
import Dropdown from "../../components/DynamicComponents/DynamicDropdown";
import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import DynamicDatePicker from "../../components/DynamicComponents/DynamicDatePicker";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { BASE_URI } from "../../utils/BaseURL";
import Loader from "../../utils/Loader";

const columns = [
  {
    Header: "S No",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center", width: "40px" }}>
        {row.original.isGroupHeader ? "" : row.original.serialNumber}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Vou. No",
    accessor: "VouNo",
    Cell: ({ value }) => (
      <div style={{ textAlign: "center", width: "70px" }}>{value}</div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Vou. Date",
    accessor: "VDate",
    Cell: ({ value }) => (
      <div>{value ? new Date(value).toLocaleDateString("en-GB") : ""}</div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Type",
    accessor: "VType",
    Cell: ({ value }) => (
      <div style={{ textAlign: "center", width: "50px" }}>{value}</div>
    ),
    isInitiallyVisible: true,
  },

  {
    Header: "Doc ID",
    accessor: "DocID",
    Cell: ({ row }) => (
      <div style={{ textAlign: "left", width: "120px" }}>
        {row.original.isGroupHeader ? "" : row.original.DocID}
      </div>
    ),

    isInitiallyVisible: false,
  },
  {
    Header: "Particulars",
    accessor: "LName",
    Cell: ({ value }) => (
      <div style={{ textAlign: "left", width: "200px" }}>{value}</div>
    ),
    headerStyle: { textAlign: "center" }, // Center-align header
    isInitiallyVisible: true,
  },
  {
    Header: "Depot",
    accessor: "DpName",
    isInitiallyVisible: false,
  },
  {
    Header: "Username",
    accessor: "UserName",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    Header: "Ledger Credit",
    accessor: "DLName",
    Cell: ({ value }) => (
      <div style={{ textAlign: "left", width: "200px" }}>{value}</div>
    ),
    isInitiallyVisible: false,
  },

  {
    Header: "Amount (Dr)",
    accessor: "DrAmt",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {typeof value === "number" ? value.toFixed(2) : value}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Amount (Cr)",
    accessor: "CrAmt",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {typeof value === "number" ? value.toFixed(2) : value}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Remarks",
    accessor: "Remark", // Keep an accessor if you want sorting based on Remark
    Cell: ({ row }) => {
      const { Remark, Remark1, Remark2 } = row.original;
      // Combine the remarks, conditionally showing only those with values
      return (
        <div style={{ textAlign: "left", width: "200px" }}>
          {[Remark, Remark1, Remark2].filter(Boolean).join(" ")}
        </div>
      );
    },
    isInitiallyVisible: true,
  },
  {
    Header: "Transaction Date",
    accessor: "TranDate",
    Cell: ({ value }) => {
      if (!value) return ""; // Handle null or undefined

      // Create a new Date object from the UTC date string
      const utcDate = new Date(value);

      // Convert UTC to IST (UTC +5:30)
      const istOffset = 5 * 60 + 30; // IST offset in minutes
      const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000); // Add offset in milliseconds

      // Increase the hour by 1
      istDate.setHours(istDate.getHours() + 1);

      // Format options for displaying date and time
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
      };

      return (
        <div style={{ width: "150px" }}>
          {istDate.toLocaleString("en-GB", options)}
        </div>
      );
    },
    isInitiallyVisible: true,
  },
];

const groupDataByDocID = (data) => {
  let groupedData = [];
  let currentDocID = null;
  let serialNumber = 0; // Initialize a serial number

  data.forEach((item) => {
    if (item.DocID !== currentDocID) {
      // Reset the serial number for each new DocID
      serialNumber = 1; // Start numbering from 1 for new group
      groupedData.push({
        isGroupHeader: true,
        DocID: item.DocID, // Optionally store the DocID for grouping purposes
      });
      currentDocID = item.DocID;
    } else {
      // Increment the serial number for same DocID
      serialNumber++;
    }

    // Add the actual data row with the serial number
    groupedData.push({ ...item, serialNumber });
  });

  return groupedData;
};

const DayBook = () => {
  const [fromDate, setFromDate] = useState(new Date()); // Separate from date
  const [toDate, setToDate] = useState(new Date()); // Separate to date
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  // Basic Information For API's
  let targetRoute = "Day Book";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  console.log(targetPage);
  const token = Cookies.get("token");
  var LogId;
  var DPCode;
  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  LogId = itemFromLocalStorage[0].LogID;

  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const formattedFromDate = fromDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");
    const formattedToDate = toDate.toISOString().slice(0, 10).replace(/-/g, "");

    const requestBody = {
      LogID: LogId,
      FormKey: targetPage.FormKey,
      Depo: DPCode,
      PartyName: "", // Assuming party name is not needed as we are filtering by agent
      AgentName: "",
      ItemGroup: "",
      ItemName: "",
      Date1: formattedFromDate,
      Date2: formattedToDate,
      RptCode: 0,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/web-reports`,
        requestBody,
        config
      );
      const groupedData = groupDataByDocID(response.data.results); // Group data by DocID
      console.log(groupedData);
      setRows(groupedData);
    } catch (error) {
      if (error.response.status === 401) {
        alert("Your Token is expired Please Login Again!!!");
        navigate("/login");
      } else {
        alert("Something went wrong!!!");
      }
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    document.title = "PTAC ERP || Daily Book";

    let timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleFromDateChange = (date) => {
    setFromDate(date);
    setRows([]); // Clear table data
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    setRows([]); // Clear table data
  };

  return (
    <div className='p-1 mx-auto bg-gray-50 rounded-lg shadow-md'>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-1 mb-1'>
        <h1 className='text-sm text-center font-bold uppercase '>Day Book</h1>
        <form onSubmit={handleSubmit} className=''>
          <div className='grid grid-cols-2 lg:grid-cols-3 space-x-2 '>
            <DynamicDatePicker
              selectedDate={fromDate}
              onDateChange={handleFromDateChange}
              maxDate={new Date()}
              dateFormat='dd/MM/yyyy'
              DateLabel='From'
            />
            <DynamicDatePicker
              selectedDate={toDate}
              onDateChange={handleToDateChange}
              maxDate={new Date()}
              dateFormat='dd/MM/yyyy'
              DateLabel='To'
            />
            <div className='col-span-2 lg:col-span-1 flex justify-end mt-1 lg:mt-5'>
              <DynamicButton variant='primary' label='Show' />
            </div>
          </div>
        </form>
      </div>
      <Table
        columns={columns}
        data={rows}
        getRowProps={(row) => ({
          style: {
            backgroundColor: row.original.isGroupHeader ? "black" : "white", // Black for header rows
            color: row.original.isGroupHeader ? "white" : "black", // White text for header rows
          },
        })}
      />
    </div>
  );
};

export default DayBook;
