import React from "react";

const DynamicInput = ({
  label,
  type = "text",
  placeholder,
  value,
  onChange,
}) => (
  <div className='flex flex-col'>
    {label && <label className='text-sm font-medium mb-1'>{label}</label>}
    <input
      type={type}
      placeholder={placeholder}
      value={value} // Controlled input value
      onChange={onChange} // Handler for input changes
      className='p-1 border border-gray-300 font-semibold bg-gray-300 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-black'
      aria-label={label} // Accessibility
    />
  </div>
);

export default DynamicInput;
