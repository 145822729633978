import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import Login from "./components/Auth/Login";
import Layout from "./components/DashBoard/Layout";
import LandingPage from "./pages/LandingPage/index";
import VisitorsEntry from "./pages/SubPages/Visitors-Entry";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const visitorsToken =
    "AxktlLqcQUdGGA4sKIsxaStKELfUXtjpbw4ithEzew0y4YYtKGyQ3XRqeKYLGGoyLXFkFV9Dd3sN14POWVks4DwoOOdSs32JhCWguOXXG4M9Fdyk4jXTv6DiBqF0Sqkj";
  const handleLogin = () => {
    const token = localStorage.getItem("token");
    Cookies.set("token", token, { expires: 4 / 24 });
    setToken(token);
  };

  return (
    <Router>
      <Routes>
        {/*<Route path='/' element={<LandingPage />} />*/}
        <Route path='/' element={<Login handleLogin={handleLogin} />} />
        <Route
          path={`/visitors/token=${visitorsToken}`}
          element={<VisitorsEntry tokens={visitorsToken} />}
        />
        <Route path='*' element={<InvalidQRCode />} />

        <Route path='/login' element={<Login handleLogin={handleLogin} />} />
        <Route
          path='/dashboard/*'
          element={token ? <Layout /> : <Navigate to='/login' />}
        />
        <Route
          path='/'
          element={
            token ? <Navigate to='/dashboard/*' /> : <Navigate to='/login' />
          }
        />
      </Routes>
    </Router>
  );
};
const InvalidQRCode = () => (
  <div className='flex flex-col items-center justify-center h-screen bg-gray-200'>
    <h1 className='text-center text-lg font-bold'>Invalid QR Code</h1>
    <p className='text-md'>The QR code you scanned is not valid.</p>
  </div>
);

export default App;
